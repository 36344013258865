import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { GlobalStyle, ContentWrapper } from "../containers/saas.style";
import { Navbar, CookiePolicy, Footer } from "@containers/common";
import { DrawerProvider } from "../contexts/DrawerContext";
import { SEO } from "@components/index";

const browser = typeof window !== "undefined" && window;

const CookiePolicyPage = () => {
    return browser && (
        <ThemeProvider theme={theme}>
            <Fragment>
                <SEO title="Cookie Policy" url="/cookie-policy" />
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar notMainPage />
                        </DrawerProvider>
                    </Sticky>
                    <CookiePolicy />
                    <Footer notMainPage />
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};

export default CookiePolicyPage;
